:root {
  --de-min-width: 900px;
  min-width: var(--de-min-width);
  --font-family: "Inter", "Roboto", "Helvetica", "Arial", sans-serif;
  --font-family-monospace: "IBM Plex Mono", monospace;
  --de-color-blue: #2f74f9;
  --de-color-green: #44e1bc;
  --de-color-plum: #ff70f1;
  --de-color-yellow: #ffe182;
  --de-color-red: #f44c4c;
  --de-color-corp-dark-blue: #0e0f18;
  --de-main-content-max-width: min(100%, 1024px);
  --de-main-content-padding: 2ch;
  --syntax-color-keyword: #004ac5;
  --syntax-color-number: black;
  --syntax-color-string: #c93abe;
  --syntax-color-comment: #0e0f18a3;
  --syntax-color-boolean: #00ae8c;
  --syntax-color-type: #00ae8c;
  --syntax-color-function: #00ae8c;
  --syntax-color-constant: #00ae8c;
  --syntax-color-builtin: #00ae8c;
  font-size: 10.5pt;
}

html, body {
  height: 100%;
  margin: 0;
}

#app {
  height: 100%;
}

#app > .splash-screen {
  place-items: center;
  height: 100%;
  animation-name: splash-appear, splash-pulse;
  animation-duration: .6s, 1.5s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-delay: 1s, 4s;
  animation-fill-mode: forwards, forwards;
  display: grid;
}

@keyframes splash-appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes splash-pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .25;
  }

  100% {
    opacity: 1;
  }
}

a {
  color: var(--de-color-blue);
}

.ace_editor {
  font-family: IBM Plex Mono, monospace;
}

.ace_gutter-layer {
  color: #0003;
  background-color: #fff;
}

code {
  font-family: var(--font-family-monospace);
}

code .token.property, code .token.keyword, .ace-tm .ace_keyword {
  color: var(--syntax-color-keyword);
}

code .token.string, .ace-tm .ace_string {
  color: var(--syntax-color-string);
}

code .token.comment, .ace-tm .ace_comment {
  color: var(--syntax-color-comment);
  font-style: italic;
}

.ace_placeholder {
  font-family: var(--font-family-monospace);
}

code .token.boolean {
  color: var(--syntax-color-boolean);
}

.ace-tm .ace_type, .ace-tm .ace_constant.ace_language {
  color: var(--syntax-color-type);
}

.ace-tm .ace_buildin.ace_language {
  color: var(--syntax-color-builtin);
}

.ace-tm .ace_support.ace_function {
  color: var(--syntax-color-function);
}

code .token.number, .ace-tm .ace_constant.ace_numeric {
  color: var(--syntax-color-number);
}

code > .token.deleted {
  background-color: #f44c4c80;
}

code > .token.inserted {
  background-color: #44e1bc80;
  border-radius: 2px;
}

.osano-cm-widget {
  display: none;
}
/*# sourceMappingURL=index.380bc29d.css.map */
