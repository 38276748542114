/* Syntax highlighting for Ace & Prism */

:root {
  --de-min-width: 900px;
  min-width: var(--de-min-width);

  --font-family: "Inter", "Roboto", "Helvetica", "Arial", sans-serif;
  --font-family-monospace: "IBM Plex Mono", monospace;
  font-size: 10.5pt;

  --de-color-blue: #2f74f9;
  --de-color-green: #44e1bc;
  --de-color-plum: #ff70f1;
  --de-color-yellow: #ffe182;
  --de-color-red: #f44c4c;

  --de-color-corp-dark-blue: #0e0f18;

  /* The max width of the centered content for our listing/details.
     Ideally this is removed as we move toward more responsive layouts.
     Note: This is also available on the MUI theme object. Using the CSS
     variables is much simpler to implement, and framework agnostic.
     For app-level concerns that are not part of a shared component
     library, CSS variables are preferred. They should eventually be
     removed from the theme in the decodable-ui package.
  */
  --de-main-content-max-width: min(100%, 1024px);
  --de-main-content-padding: 2ch;

  --syntax-color-keyword: #004ac5;
  --syntax-color-number: black;
  --syntax-color-string: rgb(201, 58, 190);
  --syntax-color-comment: rgba(14, 15, 24, 0.64);
  --syntax-color-boolean: rgb(0, 174, 140);
  --syntax-color-type: rgb(0, 174, 140);
  --syntax-color-function: rgb(0, 174, 140);
  --syntax-color-constant: rgb(0, 174, 140);
  --syntax-color-builtin: rgb(0, 174, 140);
}

html,
body {
  height: 100%;
  margin: 0;
}

#app {
  height: 100%;
}

#app > .splash-screen {
  height: 100%;
  display: grid;
  place-items: center;
  animation-name: splash-appear, splash-pulse;
  animation-duration: 0.6s, 1.5s;
  animation-delay: 1s, 4s;
  animation-fill-mode: forwards, forwards;
  animation-iteration-count: 1, infinite;
  animation-timing-function: linear, ease-in-out;
}

@keyframes splash-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes splash-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 25%;
  }
  100% {
    opacity: 1;
  }
}

a {
  color: var(--de-color-blue);
}

.ace_editor {
  font-family: "IBM Plex Mono", monospace;
}

.ace_gutter-layer {
  background-color: white;
  color: rgba(0, 0, 0, 0.2);
}

code {
  font-family: var(--font-family-monospace);
}

code .token.property,
code .token.keyword,
.ace-tm .ace_keyword {
  color: var(--syntax-color-keyword);
}

code .token.string,
.ace-tm .ace_string {
  color: var(--syntax-color-string);
}

code .token.comment,
.ace-tm .ace_comment {
  color: var(--syntax-color-comment);
  font-style: italic;
}

.ace_placeholder {
  font-family: var(--font-family-monospace);
}

code .token.boolean {
  color: var(--syntax-color-boolean);
}

.ace-tm .ace_type {
  color: var(--syntax-color-type);
}

.ace-tm .ace_constant.ace_language {
  color: var(--syntax-color-type);
}

.ace-tm .ace_buildin.ace_language {
  color: var(--syntax-color-builtin);
}

.ace-tm .ace_support.ace_function {
  color: var(--syntax-color-function);
}

code .token.number,
.ace-tm .ace_constant.ace_numeric {
  color: var(--syntax-color-number);
}

code > .token.deleted {
  background-color: #f44c4c80;
}

code > .token.inserted {
  border-radius: 2px;
  background-color: #44e1bc80;
}

/* We show a custom button for opening the cookie settings in the app's nav bar */
.osano-cm-widget {
  display: none;
}
